// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
// import { postShopsFormData } from '@/services/store.js'
import { getTicketTypesCollection, postZooTicketsTypeFormData, getGlobaltixTicket, globalTixChange } from '@/services/zoo-tickets.js'
import Utils from '@/utils/Utils'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  // props: {
  //   baseData: {
  //     type: Object,
  //     default () {
  //       return {}
  //     }
  //   }
  // },
  inject: {
    baseData: {
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      collapseLength: [],
      formData: jsonData.formData,
      secFormData: jsonData.secFormData,
      products: {
        pagination: {},
        shop_items: []
      },
      exceptional_types: []
    }
  },
  computed: {
    topVisible () {
      return Utils.isNull(this.$route.params.ticket_package_id_eq)
    }
  },
  watch: {
    // baseData: {
    //   deep: true,
    //   handler (n) {
    //     this.formData[0].createDateItem[5].Object.disabled = true
    //     this.$refs.firFormData.createDate.package_type = n.id
    //   }
    // }
  },
  created () {
    this.init()
  },
  mounted () {
    if (this.baseData && this.baseData.data && Object.keys(this.baseData.data.length > 0)) {
      this.formData[0].createDateItem[5].Object.disabled = true
      this.$refs.firFormData.createDate.ticket_package_id = Number(this.baseData.data.id.value)
    }
    if (this.$route.params.disabled && this.$route.params.disabled === 'true') {
      this.formData[0].createDateItem[5].Object.disabled = true
      this.$refs.firFormData.createDate.ticket_package_id = Number(this.$route.params.ticket_package_id_eq)
    } else {
      this.formData[0].createDateItem[5].Object.disabled = false
    }
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      const params = {}
      if (this.baseData && this.baseData.data && Object.keys(this.baseData.data.length > 0)) {
        Object.assign(params, { ticket_package_id: this.baseData.data.id.value })
      }
      getTicketTypesCollection(params).then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[5].options = res.data.collections.ticket_packages
          this.formData[0].createDateItem[6].options = res.data.collections.all_ticket_types
          this.secFormData[0].createDateItem[0].options = res.data.collections.third_party_platforms
          this.formData[2].createDateItem[0].createDateFirstItem[2].options = res.data.collections.exceptional_types
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, nextChangeProp, item, createDate) {
      if (item.prop === 'third_party_platform' && data === 'globaltix') {
        this.globaltixTicket()
      }
      if (item.prop === 'globaltix_ticket_id') {
        this.$store.state.app.loading = this.$loading(loading)
        globalTixChange(createDate.globaltix_ticket_id).then(res => {
          this.$nextTick(() => {
            const { advance_booking_in_minutes, capacity_control, peak_season, visit_date_required, validity_in_days } = res.data.globaltix_ticket
            this.$refs.firFormData.createDate.capacity_control = capacity_control
            this.$refs.firFormData.createDate.advance_booking_in_minutes = advance_booking_in_minutes
            this.$refs.firFormData.createDate.peak_season = peak_season
            this.$refs.firFormData.createDate.visit_date_required = visit_date_required
            this.$refs.firFormData.createDate.validity_in_days = validity_in_days
            const { t_start_date, t_end_date, t_description, inclusions, terms_and_conditions, use_guide, cancellation_policies, t_max_pax, t_min_pax, settlement_price_f, t_original_price_f, payable_amount_f } = res.data.globaltix_ticket
            this.$refs.secFormData.createDate.t_description = t_description
            this.$refs.secFormData.createDate.t_start_date = t_start_date
            this.$refs.secFormData.createDate.t_end_date = t_end_date
            this.$refs.secFormData.createDate.settlement_price_f = settlement_price_f
            this.$refs.secFormData.createDate.t_original_price_f = t_original_price_f
            this.$refs.secFormData.createDate.payable_amount_f = payable_amount_f
            this.$refs.secFormData.createDate.t_max_pax = t_max_pax
            this.$refs.secFormData.createDate.t_min_pax = t_min_pax
            this.$refs.secFormData.createDate.inclusions = inclusions
            this.$refs.secFormData.createDate.terms_and_conditions = terms_and_conditions
            this.$refs.secFormData.createDate.use_guide = use_guide
            this.$refs.secFormData.createDate.cancellation_policies = cancellation_policies
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      }
    },
    handleSubmitClick () {
      this.$store.state.app.loading = this.$loading(loading)
      const obj_fir = JSON.parse(JSON.stringify(this.$refs.firFormData.createDate))
      const obj_sec = JSON.parse(JSON.stringify(this.$refs.secFormData.createDate))
      Object.assign(obj_fir, obj_sec)
      if (!obj_fir.parent_ticket_type_id) obj_fir.parent_ticket_type_id = null
      obj_fir.exceptional_date_ranges_attributes.forEach((item) => {
        delete item.disabled
      })
      const params = {
        ticket_type: obj_fir
      }
      postZooTicketsTypeFormData(params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            if (this.$route.params.ticketType) {
              this.$router.push({
                name: 'zooTicketsAdd',
                params: {
                  ticket_package_id_eq: this.$route.params.ticket_package_id_eq,
                  ticketType: this.$route.params.ticketType
                }
              })
            } else {
              this.$router.push({
                name: this.topVisible ? 'zooTicketsTypeList' : 'basicTicketsTypeList',
                params: {
                  ticket_package_id_eq: this.$route.params.ticket_package_id_eq
                }
              })
            }
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    globaltixTicket () {
      getGlobaltixTicket().then((res) => {
        this.secFormData[0].createDateItem[1].options = res.data.globaltix_tickets
      })
    },
    handleCancelClick () {
      this.$router.go(-1)
    },
    afterAdd (creatData, alterData, prop) {
      const length = creatData[prop.prop].length
      const item = creatData[prop.prop][length - 1]
      if (item) {
        creatData[prop.prop][length - 1].disabled = true
      }
      creatData[prop.prop].push(alterData)
    },
    removeArray (createData, obj) {
      if (obj.index - 1 > -1) {
        delete createData[obj.prop][obj.index - 1].disabled
      }
      createData[obj.prop].splice(obj.index, 1)
    }
  }
}
